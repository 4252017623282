import { useEffect } from 'react';

const investorsMedia = () => {
  useEffect(() => {
    window.location.replace('https://corporate.rocker.com/');
  }, []);

  return null;
};
export default investorsMedia;
